<template>
  <el-dialog
    :title="title"
    width="398px"
    :visible.sync="visible"
    :before-close="close"
    :close-on-click-modal="false"
    class="dialog"
  >
    <div class="containerBox">
      <div class="job">
        {{$t('companyPosition')}}：<span class="color0083F6" @click="goJob">{{
          data.name | textFormat
        }}</span>
      </div>
      <div class="marginT">
        {{$t('times')}}：<span class="color333" v-if="data.start_time"
          >{{ data.start_time | secondFormat("LLL") }} -
          {{ data.end_time | secondFormat("T") }}</span
        >
        <span v-else>{{ data.updated_time | secondFormat("LLL") }}</span>
      </div>
<!--      <div class="marginT">-->
<!--        备注：<span class="color333">{{ data.remarks | textFormat }}</span>-->
<!--      </div>-->
      <!-- <div v-if="data.status === 2" class="btns">
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="handleConfirm"
          >确认</el-button
        >
      </div> -->
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "operationDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {
          name: "",
          status: 1,
          start_time: "",
          end_time: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    async handleConfirm() {
      let params = {
        meeting_type: 1,
        status: 2,
        position_apply_id: this.data.job_position_apply_id,
        meeting_id: 60,
        one2one_chat_id: this.data.chat_id,
        position_apply_status: 6,
        invitation_type: 1,
      };
      if (this.MEETING_STATUS == 3) {
        params.meeting_type = 2;
      }
      params.source = this.PJSource
      let res = await this.$store.dispatch(
        "baseConsole/talent_handleDaily_chat",
        params
      );
      if (res.success) {
        if (typeof res.info === "string") {
          this.$message.warning(res.info);
        } else {
          this.$message.success("邀请成功");
          this.$emit("handleDaily_chat");
        }
      } else {
        this.$message.error("邀请失败");
      }
    },
    goJob() {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: this.data.position_id,
          }),
        },
      });
    },
  },
  computed: {
    title() {
      let str = "";
      if (this.data.status === 2 || this.data.status === 7) {
        str = this.$t("inviteInterview1");
      }
      return str;
    },
  },
};
</script>

<style scoped lang="less">
.dialog {
  /deep/ .el-dialog__body {
    padding: 0 24px;
  }
  /deep/ .el-dialog__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .el-dialog__title {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .containerBox {
    height: 150px;
    font-weight: bold;
    color: #999999;
    overflow: hidden;
    .job {
      margin-top: 24px;
    }
    .color0083F6 {
      color: #0083f6;
    }
    .color333 {
      color: #333333;
    }
    .marginT {
      margin-top: 14px;
    }
    .btns {
      margin-top: 24px;
      float: right;
    }
  }
}
</style>